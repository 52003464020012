<template>
  <section class="news bg-line" data-aos="fade-up">
    <h1 class="news__title frames-title" v-if="language && Object.keys(language).length > 0">{{language["menu::news"][locale]}}</h1>
    <div class="news__list" v-if="newsList.length > 0">
      <swiper
        :breakpoints="swiperOption.breakpoints"
        :modules="modules"
        :loop="true"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
        }"
        navigation
      >
        <swiper-slide  v-for="(item, index) in newsList" :key="index">
          <div class="item">
            <router-link :to="`/detail/${item.id}`">
              <div class="item__avt">
                <img :src="item?.image" alt="banner" />
              </div>
              <p class="item__category">
                {{item[locale]?.category_name}}
              </p>
              <h3 class="item__title" v-html="item[locale]?.title"></h3>
              <SeeMore :link='`/detail/${item?.id}`' :language="language"/>
            </router-link>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="news__see-more" v-if="language && Object.keys(language).length > 0">
      <router-link :to="'/news'">
        <p>{{language["home::viewallnews"][locale]}}</p>
      </router-link>
    </div>
  </section>
</template>
<script>
import SeeMore from "../button/seeMore.vue";
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { mapGetters } from 'vuex';
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/modules/navigation/navigation.min.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
    SeeMore,
  },
    props: {
      newsList: [],
      language: null
    },
  setup() {
    return {
      modules: [Navigation, Autoplay],
    };
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  data() {
    return {
      swiperOption: {
        breakpoints: {
          320: {
            slidesPerView: 1.3,
            spaceBetween: 10,
          },
          1023: {
            slidesPerView: 2.9,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 3.5,
            centeredSlides: false,
            spaceBetween: 20,
          },
        },
      },
      data: [
        {
          avt: require("../../assets/img/home/new1.png"),
          category: "Tin tức",
          title:
            "Eco-industrial park initiative for sustainable industrial zones",
        },
        {
          avt: require("../../assets/img/home/new2.png"),
          category: "Tin tức",
          title:
            "Eco-industrial park initiative for sustainable industrial zones",
        },
        {
          avt: require("../../assets/img/home/new3.png"),
          category: "Tin tức",
          title:
            "Eco-industrial park initiative for sustainable industrial zones",
        },
        {
          avt: require("../../assets/img/home/new3.png"),
          category: "Tin tức",
          title:
            "Eco-industrial park initiative for sustainable industrial zones",
        },
        {
          avt: require("../../assets/img/home/new3.png"),
          category: "Tin tức",
          title:
            "Eco-industrial park initiative for sustainable industrial zones",
        },
        {
          avt: require("../../assets/img/home/new3.png"),
          category: "Tin tức",
          title:
            "Eco-industrial park initiative for sustainable industrial zones",
        },
        {
          avt: require("../../assets/img/home/new3.png"),
          category: "Tin tức",
          title:
            "Eco-industrial park initiative for sustainable industrial zones",
        },
      ],
      link: "/",
    };
  },
  methods: {},
};
</script>
