<template>
  <div class="main-home">
    <Banner :data="dataSlideA" :contentBanner="contentBanner" />
    <InfoNumber :language="language"/>
    <Overview :dataBlock="dataBlock" :language="language"/>
    <Investor :dataBlock="dataBlock" :language="language"/>
    <InfoVisit :dataBlock="dataBlock" :language="language"/>
    <News :newsList="news" :language="language"/>
    <!-- <Partner /> -->
    <Contact :language="language"/>
  </div>
</template>

<script>
/* global $ */
import Banner from "@/components/home/Banner.vue";
import InfoNumber from "@/components/home/infoNumber.vue";
import Overview from "@/components/home/overviewKCN.vue";
import Investor from "@/components/home/investor.vue";
import InfoVisit from "@/components/home/InfoVisit.vue";
import News from "@/components/home/News.vue";
// import Branch from "@/components/home/Branch.vue";
// import Partner from "@/components/common/Partner.vue";
import Contact from "@/components/home/Contact.vue";
import HomeSerive from "@/services/HomeSerive";
import { mapGetters, mapActions } from "vuex";
import CommonSerive from "@/services/CommonSerive";

export default {
  components: {
    Banner,
    InfoNumber,
    Overview,
    Investor,
    InfoVisit,
    News,
    // Partner,
    Contact,
  },
  data() {
    return {
      data: "",
      dataPopUp: "",
      contentBanner: {},
      dataSlideA: [
        {
          info: "CHÀO MỪNG ĐẾN",
          title: "Khu Công Nghiệp Amber Tây Bắc Hồ Xá",
          image: require("../assets/img/home/banner.jpg"),
        },
        {
          info: "CHÀO MỪNG ĐẾN",
          title: "Khu Công Nghiệp Amber Tây Bắc Hồ Xá",
          image: require("../assets/img/home/banner2.jpg"),
        },
        {
          info: "CHÀO MỪNG ĐẾN",
          title: "Khu Công Nghiệp Amber Tây Bắc Hồ Xá",
          image: require("../assets/img/home/banner3.jpg"),
        },
      ],
      dataBranch: [],
      dataBlock: [],
      itemMain: [],
      itemSub: [],
      news: [],
      language: [],
      dataMenuActive: {
        main: -1,
        sub: -1,
      },
    };
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    this.dataHome();
    this.pallax();
    this.dataHomea();
    this.getLang();
  },
  computed: {
    ...mapGetters(["locale", "info"]),

  },
  methods: {
    ...mapActions(["getLang"]),
    // activePopup() {
    //   $("#popupcommonpromos").removeClass("hide");
    //   $("#popupcommonpromos").addClass("active");
    // },

    pallax() {
      $(window).on("scroll", function () {
        var trainPosition = Math.round(
          ($(window).scrollTop() / $(window).height()) * 5
        );
        $(".prallax-left").css(
          "transform",
          "translateX(" +
            (trainPosition + ($(window).width() < 620 ? -30 : 20)) +
            "%)"
        );
        $(".prallax-right").css(
          "transform",
          "translateX(" + (-trainPosition + 30) + "%)"
        );
      });
    },
    async dataHome() {
      await HomeSerive.getHome()
        .then((resp) => {
          console.log(resp.data)
          this.data = resp.data.data;
          this.dataSlideA = this.data.imagesBanner;
          this.dataBranch = this.data.brands;
          this.contentBanner = this.data.banner;
          this.dataBlock = this.data.blocks;
          this.news = this.data.news;
        })
        .catch(() => {});
    },
     async dataHomea() {
      await CommonSerive.getLang()
        .then((resp) => {
          this.language = resp.data.data
          console.log("🚀 ~ file: Contact.vue ~ line 138 ~ .then ~ resp.data.data", resp.data.data["home::welookforward"].vi)
        })
        .catch(() => {});
    },
    // async dataPop() {
    //   await HomeSerive.getPopUp()
    //     .then((resp) => {
    //       this.dataPopUp = resp.data.data;
    //       if (this.info.popup == 1 && this.dataPopUp) {
    //         setTimeout(() => {
    //           this.activePopup();
    //         }, 1500);
    //       }
    //     })
    //     .catch(() => {});
    // },
  },
};
</script>
