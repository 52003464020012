<template>
  <section class="info-number counter-show" id="info-number" data-aos="fade-up" v-if="language && Object.keys(language).length > 0">
    <div class="list-number">
      <div class="item" v-for="(item, index) in listNumber" :key="index">
        <h2 class="item__number count-number">
          {{ item.number }}
        </h2>
        <p class="item__title" v-html="language[item.title][locale]"></p>
      </div>
    </div>
    <ButtonContact className="white" link="javascript:void(0)" @click="scrollContact()" :language="language"/>
  </section>
</template>
<script>
import ButtonContact from "../button/buttonContact.vue";
import { conuterNumber3 } from "../../utils/import";
import { mapGetters } from 'vuex';

export default {
  components: {
    ButtonContact,
  },
  props:{
    language: null
  },
  data() {
    return {
      listNumber: [
        {
          number: 1097,
          title: "other::TOTALINVESTMENT10",
        },
        {
          number: 214.77,
          title: "home::totalarea",
        },
        {
          number: 2071,
          title: "home::land",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["locale", "info"]),
  },
  mounted() {
    conuterNumber3(".counter-show", ".count-number");
  },
  methods: {
    scrollContact() {
       window.scrollTo({
        top: document.getElementById('ambercontact').offsetTop - 200,
        left: 0,
        behavior: "smooth",
      });
    }
  }
};
</script>
