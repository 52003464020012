<template>
  <section class="info-investor bg-line" data-aos="fade-up">
    <div class="investor-cover">
      <div class="info-investor__content">
        <div class="detail">
          <div class="detail__info info-hr" v-if="dataBlock.length > 0 && dataBlock[dataBlock.length - 3]">
            {{ dataBlock[dataBlock.length - 3][locale]?.title }}
          </div>
          <!-- <div class="detail__title">
            Quang Anh - Nhà phát triển bất động sản chuyên nghiệp
          </div> -->
          <div class="detail__des" v-if="dataBlock.length > 0 && dataBlock[dataBlock.length - 3]"
            v-html="dataBlock[dataBlock.length - 3][locale]?.content">
          </div>
          <div class="detail__content">
            <div class="detail-number project-show">
              <div class="list-number" v-if="language && Object.keys(language).length > 0">
                <div class="item" v-for="(item, index) in listNumber" :key="index">
                  <h2 class="item__number">
                    <span class="plus">+</span> <span class="project-number">{{ item.number }} </span>
                  </h2>
                  <p class="item__title" v-html="language[item.title][locale]" style="margin-top: 23px;"></p>
                </div>
              </div>
            </div>
          </div>
          <SeeMore :className="'arrow-blue'" :link="'/investor'" :language="language" />
        </div>
      </div>
      <div class="info-investor__map" v-if="dataBlock.length > 0 && dataBlock[dataBlock.length - 3]">
        <img :src="dataBlock[dataBlock.length - 3].image" alt="" />
      </div>
    </div>
    <div class="info-investor__animation">
      <!-- <div class="text-prallax"> -->
      <h1 class="prallax-left left">AMBER IP</h1>
      <h1 class="prallax-right right">TAY BAC HO XA</h1>
      <!-- </div> -->
    </div>
    <div class="animation-bg">
      <img src="../../assets/img/home/bg-invertor.png" alt="" />
    </div>
  </section>
</template>
<script>
import SeeMore from "../button/seeMore.vue";
import { conuterNumber4 } from "../../utils/import";
import { mapGetters } from 'vuex';
export default {
  components: {
    SeeMore,
  },
  props: {
    dataBlock: [],
    language: null
  },
  data() {
    return {
      listNumber: [
        {
          number: 20,
          title: "other::IMPLEMENTATION",
        },
        {
          number: 3000,
          title: "other::HAEARTH EDGE",
        },
        {
          number: 100,
          title: "other::INTERNATIONAL20",
        },
      ],
    };
  },
  mounted() {
    conuterNumber4(".project-show", ".project-number");
  },
  computed: {
    ...mapGetters(["locale"]),
  },
};
</script>
