<template>
  <section class="info-visit bg-line" data-aos="fade-up">
    <div class="info-visit__bg" v-if="dataBlock.length > 0 && dataBlock[0]">
      <img :src="dataBlock[0].image" :alt="dataBlock[0][locale]?.title" />
    </div>
    <div class="info-visit__content" v-if="dataBlock.length > 0">
      <div class="post-info">
        <h4 class="post-info__address">{{ dataBlock[0][locale]?.title }}</h4>
        <div class="post-info__title">
          <h2 v-html="dataBlock[0][locale]?.content"></h2>
          <!-- <h2 v-html="dataBlock[0][locale]?.content"> -->
          <!-- <span class="b_txt">Quảng Trị - Miền Đất Lành Cho Nhà Đầu Tư.</span>
            Vị trí chiến lược nằm trong trung tâm thủ phủ năng lượng miền Trung -->
          <!-- </h2> -->
        </div>
      </div>
      <SeeMore :link="'/info'" :language="language"/>
    </div>
  </section>
</template>
<script>
import SeeMore from "../button/seeMore.vue";
import { mapGetters } from 'vuex';
export default {
  components: {
    SeeMore,
  },
  props: {
    dataBlock: [],
    language: null
  },
  data() {
    return {
      listNumber: [
        {
          number: 925,
          title: "Tổng Mức Đầu tư <br> 925 Tỷ",
        },
        {
          number: 21477,
          title: "TỔNG DIỆN TÍCH <br> 214,77 HA",
        },
        {
          number: 2071,
          title: "THỜI GIAN THUÊ ĐẤT <br> ĐẾN NĂM 2071",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["locale"]),
  },
};
</script>
