<template>
  <section class="info-factory">
    <div class="overview-kcn" data-aos="fade-up">
      <div class="info-detail overview-kcn__post">
        <div
          class="item"
          v-if="dataBlock.length > 0 && dataBlock[dataBlock.length - 1]"
        >
          <h3 class="item__info info-hr">
            {{ dataBlock[dataBlock.length - 1][locale]?.title }}
          </h3>
          <h1
            class="item__des"
            v-html="dataBlock[dataBlock.length - 1][locale]?.content"
          ></h1>
          <!-- <p class="item__des" v-html="dataBlock[dataBlock.length - 1][locale]?.content"></p> -->
          <SeeMore :link="'/about'" :language="language" />
        </div>
      </div>
      <div
        class="overview-bg"
        @mouseenter="mouseEnter"
        @mousemove="mousemove"
        @mouseleave="mouseLeave"
        id="zoom"
        v-if="dataBlock.length > 0 && dataBlock[dataBlock.length - 1]"
      >
        <img
          id="mapoverview"
          :src="dataBlock[dataBlock.length - 1].image"
          alt="icon translate"
        />
        <div class="img-zoom">
          <button type="button" @click="zoomin()">+</button>
          <button type="button" @click="zoomout()">-</button>
        </div>
      </div>
      <!-- <div class="Sirv" data-effect="zoom">
        <img
          src="http://admin.kcntaybachoxa.com.vn/image/202208100624z3632618096799_b5a42d9d376bdf2a475b78da45217150.jpg"
        />
      </div> -->
    </div>
    <div class="favorable-kcn" data-aos="fade-up">
      <div
        class="overview-bg"
        v-if="dataBlock.length > 0 && dataBlock[dataBlock.length - 2]"
      >
        <img
          :src="dataBlock[dataBlock.length - 2].image"
          alt="icon translate"
        />
      </div>
      <div class="favorable-over">
        <div class="info-detail favorable-kcn__post">
          <div
            class="item"
            v-if="dataBlock.length > 0 && dataBlock[dataBlock.length - 2]"
          >
            <h3 class="item__info info-hr">
              {{ dataBlock[dataBlock.length - 2][locale]?.title }}
            </h3>
            <!-- <h1 class="item__title" v-html="dataBlock[dataBlock.length - 1][locale]?.title"></h1> -->
            <p
              class="item__des"
              v-html="dataBlock[dataBlock.length - 2][locale]?.content"
            ></p>
          </div>
          <div
            class="list-favorable"
            v-if="language && Object.keys(language).length > 0"
          >
            <div
              class="item"
              v-for="(item, index) in listFavorable"
              :key="index"
            >
              <div class="item__number">{{ item.number }}</div>
              <div class="item__content">
                <h4 class="fa-title" v-html="language[item.title][locale]"></h4>
                <p class="fa-des" v-html="language[item.des][locale]"></p>
              </div>
            </div>
          </div>
          <SeeMore :link="'/why-choose'" :language="language" />
        </div>
        <Contact
          className="white"
          link="javascript:void(0)"
          @click="scrollContact()"
          :language="language"
        />
      </div>
    </div>
  </section>
</template>
<script>
// /* global $ */
import SeeMore from "../button/seeMore.vue";
import Contact from "../button/buttonContact.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    SeeMore,
    Contact,
  },
  props: {
    dataBlock: [],
    language: null,
  },
  data() {
    return {
      currentZoom: 1,
      post1: {
        info: "TỔNG QUAN KHU CÔNG NGHIỆP",
        title:
          "KCN Amber Tây Bắc Hồ Xá với quy mô 214,77ha nằm trên địa bàn huyện Vĩnh Linh, tỉnh Quảng Trị có vị trí vô cùng đắc địa.",
        des: "Sở hữu lợi thế về hệ thống hạ tầng giao thông như nằm ngay trên trục đường quốc lộ 1A, kế cận nhiều cảng biển, sân bay lớn… Nguồn lao động dồi dào được đào tạo bài bản, cùng vị trí nằm gần nguồn tài nguyên phong phú chính là những ưu thế đặc biệt",
      },
      post2: {
        info: "ƯU THẾ Amber Tây Bắc Hồ Xá",
        title:
          "Đóng vai trò quan trọng trong việc phát triển cho vùng kinh tế trọng điểm miền Trung",
        des: "KCN Amber Tây Bắc Hồ Xá được đánh giá là điểm sáng có nhiều triển vọng thu hút các nhà đầu tư trong & ngoài nước.",
      },
      listFavorable: [
        {
          number: "01",
          title: "menu::criticalposition",
          des: "other::transportationdue",
        },
        {
          number: "02",
          title: "other::rentalprice",
          des: "other::vietnamandin",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  mounted() {
    // $(document).ready(function () {
    //   let scale = 1;
    //   let pointX = 0;
    //   let pointY = 0;
    //   let start = { x: 0, y: 0 };
    //   $("#zoom").onmousemove = function (e) {
    //     console.log("12324334");
    //     e.preventDefault();
    //     pointX = e.clientX - start.x;
    //     pointY = e.clientY - start.y;
    //     $("#zoom").style.transform =
    //       "translate(" + pointX + "px, " + pointY + "px) scale(" + scale + ")";
    //   };
    // });
  },
  methods: {
    mouseLeave() {
      console.log(1);
      // this.mouseCommon(e);
    },
    mousemove(e) {
      console.log(e.clientX);
      console.log(e.clientY);
      // this.mouseCommon(e);
    },
    mouseEnter() {
      console.log(3);
      // this.mouseCommon(e);
    },
    mouseCommon(e) {
      let scale = this.currentZoom;
      let pointX = 0;
      let pointY = 0;
      // let start = { x: 648, y: 107 };
      let zoom = document.getElementById("zoom");
      console.log(e);
      e.preventDefault();
      pointX = e.clientX / 8;
      pointY = e.clientY / 8;
      console.log("s", pointX);
      console.log("s", pointY);
      zoom.style.transform =
        "translate(" + pointX + "px, " + pointY + "px) scale(" + scale + ")";
    },
    scrollContact() {
      window.scrollTo({
        top: document.getElementById("ambercontact").offsetTop - 200,
        left: 0,
        behavior: "smooth",
      });
    },
    zoomin() {
      this.currentZoom += 0.5;
      let myImg = document.getElementById("mapoverview");
      myImg.style.transform = "scale(" + this.currentZoom + ")";
    },
    zoomout() {
      let myImg = document.getElementById("mapoverview");
      if (this.currentZoom > 1) {
        this.currentZoom -= 0.5;
        myImg.style.transform = "scale(" + this.currentZoom + ")";
      }
    },
  },
};
</script>