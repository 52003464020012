import http from "@/http/http-common";
// const resource = "/api/frontend";
class HomeSerive {
    getHome() {
        return http.get(`/home`);
    }
    // getPopUp() {
    //     return http.get(`/popup`);
    // }
}

export default new HomeSerive();
