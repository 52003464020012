<template>
  <div :class="[className ? className : '', 'button-contact']" v-if="language && Object.keys(language).length > 0">
    <a :href="link ? link : '/'" class="button-contact__link">
      <p>{{language["home::contactnow"][locale]}}</p>
    </a>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    link: null,
    className: null,
    language: null
  },
  computed: {
    ...mapGetters(["locale", "info"]),
  },
};
</script>

